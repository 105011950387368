"use client"

import * as TooltipPrimitive from "@radix-ui/react-tooltip"
import * as React from "react"

import { cn } from "@/utils/cn"

const TooltipProvider = TooltipPrimitive.Provider

const Tooltip = TooltipPrimitive.Root

const TooltipTrigger = TooltipPrimitive.Trigger

const TooltipPortal = TooltipPrimitive.Portal

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> & { disabled?: boolean }
>(({ className, sideOffset = 4, disabled, ...props }, ref) => {
  // Conditionally render the tooltip content based on the disabled prop
  if (disabled) {
    return null
  }

  return (
    <TooltipPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={cn(
        "dg-z-50 dg-overflow-hidden dg-rounded-md dg-bg-[#212504] dg-px-3 dg-py-1.5 dg-text-xs dg-text-white dg-animate-in dg-fade-in-0 dg-zoom-in-95 data-[state=closed]:dg-animate-out data-[state=closed]:dg-fade-out-0 data-[state=closed]:dg-zoom-out-95 data-[side=bottom]:dg-slide-in-from-top-2 data-[side=left]:dg-slide-in-from-right-2 data-[side=right]:dg-slide-in-from-left-2 data-[side=top]:dg-slide-in-from-bottom-2",
        className,
      )}
      {...props}
    />
  )
})

TooltipContent.displayName = TooltipPrimitive.Content.displayName

export { Tooltip, TooltipContent, TooltipPortal, TooltipProvider, TooltipTrigger }
